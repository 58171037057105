(<template>
  <section class="landing-title">
    <div class="landing-title__text-wrapper">
      <h1 class="landing-title__title">{{ title }}</h1>
    </div>
  </section>
</template>)

<script>
  export default {
    computed: {
      title() { return this.$gettext('The new standard in interpretation'); },
    }
  };
</script>

<style scoped>
  .landing-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 520px;
    background-image: url(~@assets/imgs/undefined_imgs/landing_girl.jpg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .landing-title__text-wrapper {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 900px;
    max-width: 100%;
    padding-top: 275px;
    color: #fff;
    font-weight: bold;
    text-align: center;
  }

  .landing-title__title {
    font-size: 40px;
    line-height: 1.15;
  }

  @media (max-width: 767px) {
    .landing-title {
      height: 300px;
      background-image: url(~@assets/imgs/undefined_imgs/landing_girl_mobile.jpg);
    }

    .landing-title__text-wrapper {
      padding-top: 0;
    }

    .landing-title__title {
      max-width: 500px;
      font-size: 30px;
    }
  }

  @media (max-width: 520px) {
    .landing-title {
      height: 210px;
    }

    .landing-title__title {
      max-width: 300px;
    }
  }
</style>
