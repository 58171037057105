(<template>
  <section class="testimonials">
    <div class="testimonials__wrapper">
      <div class="testimonials__logos"></div>
      <div v-if="$isOnDomain('tikktalk')"
           class="testimonials__nav-intro">
        <div class="testimonials__nav-intro-cont">
          <p class="testimonials__nav-intro-title">{{ $gettext('Do you work for NAV and need an interpreter?') }}</p>
          <p class="testimonials__nav-intro-text">{{ $gettext('We have a special booking form just for you.') }}</p>
        </div>
        <router-link :to="$makeRoute({name: 'OpenBookingInterpretation', params: {organization: 'nav'}})"
                     class="sk-btn sk-btn--default testimonials__nav-intro-btn">{{ $gettext('To the booking form') }}</router-link>
      </div>
      <div class="testimonials__texts-wrapper">
        <tile v-for="tile in tiles"
              :key="tile.author"
              :params="tile" />
      </div>
      <div class="testimonials__btn-wrapper">
        <router-link :to="$makeRoute({name: 'SignUp'})"
                     class="sk-btn sk-btn--default testimonials__btn">{{ $gettext('Sign up for free') }}</router-link>
      </div>
    </div>
  </section>
</template>)

<script>
  import TestimonialTile from '@/components/landing/LandingTestimonialTile';

  export default {
    components: {
      tile: TestimonialTile
    },
    computed: {
      tiles() {
        return [
          {
            author: 'helse-forde',
            title: this.$gettext('Helse Førde uses TikkTalk for more reliable interpretation services'),
            text: this.$gettext('TikkTalk delivered qualified interpreters in 97 per cent of assignments for Hele Førde in 2017. That\'s twice as good as municipal interpretation services and almost five times as good as private interpretation services elsewhere in the public sector...'),
            link: this.$gettext('https://www.tikktalk.com/en/2018/01/15/helse-forde-interpretation-tikktalk/')
          },
          {
            author: 'enebakk-kommune',
            title: this.$gettext('Enebakk municipality has found its favourite interpreters through TikkTalk'),
            text: this.$gettext('Head of refugee services, Wenche Bjerke, says TikkTalk offers the best quality for the money – We are mainly concerned with the quality of interpretation, says Wenche Bjerke. She is the head of refugee services in Enebakk municipality in Eastern Norway, and leads a team which often uses interpreters...'),
            link: this.$gettext('https://www.tikktalk.com/en/2017/11/16/enebakk-municipality-has-found-its-favourite-interpreters-through-tikktalk/')
          },
          {
            author: 'kvam-herad',
            title: this.$gettext('Kvam Municipality uses TikkTalk to book highly educated interpreters'),
            text: this.$gettext('Malin Namdal, head of refugee services in Kvam, uses TikkTalk to book highly educated interpreters. Kvam is a medium-sized municipality along the Hardangerfjord in Western Norway. The municipality has taken in about 30 refugees per year for the last two years, and every week there is a need for interpreters...'),
            link: this.$gettext('https://www.tikktalk.com/en/2017/11/16/kvam-municipality-uses-tikktalk-to-book-highly-educated-interpreters/')
          }
        ];
      }
    }
  };
</script>

<style scoped>
  .testimonials {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 30px 20px 50px;
  }

  .testimonials__wrapper {
    display: block;
    width: 1140px;
    max-width: 100%;
  }

  .testimonials__logos {
    display: block;
    width: 1100px;
    max-width: 100%;
    height: 67px;
    margin: 0 auto;
    background-image: url(~@assets/imgs/undefined_imgs/landing-logos2.svg);
    background-position: 50% 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
  }

  .testimonials__nav-intro {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    padding: 16px 25px 16px 115px;
    border-radius: 5px;
    background-color: #73829c;
    background-color: var(--color-primary-lighter);
    box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 10px 25px 0 rgba(0, 34, 102, 0.2);
  }

  .testimonials__nav-intro::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 25px;
    width: 100px;
    height: 60px;
    margin-top: -30px;
    background-image: url(~@assets/imgs/organizations_logos/nav_logo.svg);
    background-position: 0 50%;
    background-size: auto 60px;
    background-repeat: no-repeat;
  }

  .testimonials__nav-intro-cont {
    flex-shrink: 0;
    padding: 0 20px;
  }

  .testimonials__nav-intro-title {
    font-weight: bold;
    font-size: 18px;
  }

  .testimonials__nav-intro-text {
    font-size: 16px;
  }

  .testimonials__nav-intro-btn {
    width: 300px;
  }

  .testimonials__texts-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 100%;
  }

  .testimonials__btn-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 55px;
  }

  .testimonials__btn {
    display: inline-block;
    width: auto;
    padding: 0 45px;
  }

  @media (max-width: 767px) {
    .testimonials__texts-wrapper {
      flex-wrap: wrap;
    }

    .testimonials__logos {
      height: 100px;
      background-image: url(~@assets/imgs/undefined_imgs/landing-logos-mobile.svg);
      background-size: contain;
    }

    .testimonials__nav-intro {
      display: block;
      padding: 25px;
    }

    .testimonials__nav-intro::before {
      position: static;
      display: block;
      margin: 0 auto;
    }

    .testimonials__nav-intro-cont {
      margin: 15px 0;
      padding: 0;
    }

    .testimonials__nav-intro-title {
      font-size: 16px;
    }

    .testimonials__nav-intro-text {
      font-size: inherit;
    }

    .testimonials__nav-intro-btn {
      width: 100%;
    }
  }
</style>
