// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/landing_girl.jpg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/undefined_imgs/landing_girl_mobile.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "\n.landing-title[data-v-6ad9b144] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  width: 100%;\n  height: 520px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-position: 50% 50%;\n  background-size: cover;\n  background-repeat: no-repeat;\n}\n.landing-title__text-wrapper[data-v-6ad9b144] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-flex: 1;\n      -ms-flex-positive: 1;\n          flex-grow: 1;\n  -webkit-box-orient: vertical;\n  -webkit-box-direction: normal;\n      -ms-flex-direction: column;\n          flex-direction: column;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: center;\n      -ms-flex-pack: center;\n          justify-content: center;\n  width: 900px;\n  max-width: 100%;\n  padding-top: 275px;\n  color: #fff;\n  font-weight: bold;\n  text-align: center;\n}\n.landing-title__title[data-v-6ad9b144] {\n  font-size: 40px;\n  line-height: 1.15;\n}\n@media (max-width: 767px) {\n.landing-title[data-v-6ad9b144] {\n    height: 300px;\n    background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n.landing-title__text-wrapper[data-v-6ad9b144] {\n    padding-top: 0;\n}\n.landing-title__title[data-v-6ad9b144] {\n    max-width: 500px;\n    font-size: 30px;\n}\n}\n@media (max-width: 520px) {\n.landing-title[data-v-6ad9b144] {\n    height: 210px;\n}\n.landing-title__title[data-v-6ad9b144] {\n    max-width: 300px;\n}\n}\n", ""]);
// Exports
module.exports = exports;
