(<template>
  <landing-wrapper class="landing-page">
    <landing-title />
    <testimonials />
  </landing-wrapper>
</template>)

<script>
  import LandingWrapper from '@/components/landing/LandingWrapper';
  import LandingTitleSection from '@/components/landing/LandingTitleSection';
  import LandingTestimonialsSection from '@/components/landing/LandingTestimonialsSection';

  export default {
    asyncData({store}) {
      let promisesArr = '';

      if (!store.state.selectsData.language || !store.state.selectsData.language.length) {
        promisesArr ? promisesArr.push(store.dispatch('fetchSelectList', 'language')) : promisesArr = [store.dispatch('fetchSelectList', 'language')];
      }

      return promisesArr ? Promise.all(promisesArr) : '';
    },
    components: {
      'landing-wrapper': LandingWrapper,
      'landing-title': LandingTitleSection,
      testimonials: LandingTestimonialsSection,
    },
    computed: {
      currentLang() { return this.$language.current; }
    },
    watch: {
      currentLang() {
        this.$store.commit('removeSelectsData', 'language');
      },
      activeModal(newVal, oldVal) {
        if (!newVal && oldVal) this.$router.push(this.$makeRoute({name: this.$route.name}));
      }
    },
    beforeMount() {
      this.$fetcher.setLocale(this.$cookieManager.getCookie('locale') || this.$cookieManager.getCookie('serverLocale'), false).then(() => {
        if (!this.$store.state.selectsData.language || !this.$store.state.selectsData.language.length) {
          this.$store.dispatch('fetchSelectList', 'language');
        }
      });
    }
  };
</script>
