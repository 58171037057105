// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/undefined_imgs/landing-helse-forde-logo.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("@assets/imgs/undefined_imgs/landing-enebakk-logo.svg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("@assets/imgs/undefined_imgs/landing-kvam-herad-logo.jpg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
exports.push([module.id, "\n.testimonial-tile[data-v-2829eb3b] {\n  display: block;\n  width: 555px;\n  max-width: calc(50% - 15px);\n  margin-top: 30px;\n  padding: 25px;\n  border-radius: 5px;\n  background-color: #fff;\n  -webkit-box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 10px 25px 0 rgba(0, 34, 102, 0.2);\n          box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 10px 25px 0 rgba(0, 34, 102, 0.2);\n}\n.testimonial-tile__logo[data-v-2829eb3b] {\n  display: block;\n  width: 100%;\n  height: 50px;\n  background-position: 0 50%;\n  background-repeat: no-repeat;\n}\n.testimonial-tile__logo--helse-forde[data-v-2829eb3b] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: auto 46px;\n}\n.testimonial-tile__logo--enebakk-kommune[data-v-2829eb3b] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-size: auto 49px;\n}\n.testimonial-tile__logo--kvam-herad[data-v-2829eb3b] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ");\n  background-position: -42px 50%;\n  background-size: auto 40px;\n}\n.testimonial-tile__title[data-v-2829eb3b] {\n  display: block;\n  width: 100%;\n  margin: 15px 0;\n  font-weight: bold;\n  font-size: 16px;\n}\n.testimonial-tile__learn-more-link[data-v-2829eb3b] {\n  white-space: nowrap;\n}\n@media (max-width: 767px) {\n.testimonial-tile[data-v-2829eb3b] {\n    width: 100%;\n    max-width: initial;\n}\n}\n", ""]);
// Exports
module.exports = exports;
