(<template>
  <article class="testimonial-tile">
    <header :class="[`testimonial-tile__logo--${params.author}`]"
            class="testimonial-tile__logo"></header>
    <h1 class="testimonial-tile__title">{{ params.title }}</h1>
    <p class="testimonial-tile__text">
      {{ params.text }}
      <a class="sk-link testimonial-tile__learn-more-link"
         :href="params.link">{{ $gettext('LEARN MORE') }}</a>
    </p>
  </article>
</template>)

<script>
  export default {
    props: {
      params: {
        type: Object,
        default() {
          return {};
        }
      }
    }
  };
</script>

<style scoped>
  .testimonial-tile {
    display: block;
    width: 555px;
    max-width: calc(50% - 15px);
    margin-top: 30px;
    padding: 25px;
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 34, 102, 0.1), 0 10px 25px 0 rgba(0, 34, 102, 0.2);
  }

  .testimonial-tile__logo {
    display: block;
    width: 100%;
    height: 50px;
    background-position: 0 50%;
    background-repeat: no-repeat;
  }

  .testimonial-tile__logo--helse-forde {
    background-image: url(~@assets/imgs/undefined_imgs/landing-helse-forde-logo.svg);
    background-size: auto 46px;
  }

  .testimonial-tile__logo--enebakk-kommune {
    background-image: url(~@assets/imgs/undefined_imgs/landing-enebakk-logo.svg);
    background-size: auto 49px;
  }

  .testimonial-tile__logo--kvam-herad {
    background-image: url(~@assets/imgs/undefined_imgs/landing-kvam-herad-logo.jpg);
    background-position: -42px 50%;
    background-size: auto 40px;
  }

  .testimonial-tile__title {
    display: block;
    width: 100%;
    margin: 15px 0;
    font-weight: bold;
    font-size: 16px;
  }

  .testimonial-tile__learn-more-link {
    white-space: nowrap;
  }

  @media (max-width: 767px) {
    .testimonial-tile {
      width: 100%;
      max-width: initial;
    }
  }
</style>
